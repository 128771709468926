<template>
<div>
  <b-navbar style="background: #00348d; height: 47px; margin-top:-21px; margin-left: -15px; margin-right: -15px;">
    <b-navbar-nav>
      <b-nav pills id="top-menu-subbar" style="background: #00348d; font-size: 16px; color: white; height: 47px; line-height: 33px;">
        <b-nav-item :to="{ name: 'Lineage' }" exact exact-active-class="active">Tree</b-nav-item>
        <b-nav-item :to="{ name: 'LineageTable' }" exact exact-active-class="active">Table</b-nav-item>
        <b-nav-item :to="{ name: 'LineageSankey' }" exact exact-active-class="active">Sankey</b-nav-item>
      </b-nav>
    </b-navbar-nav>
  </b-navbar>
  <router-view></router-view>
</div>
</template>

<script>
export default {
  name: 'LineageLayout',
  components: {
  },
  computed: {
  },
  data () {
    return {
    }
  },
  created: function () {
    document.title = "ESG Metrics"
  },
  methods: {
  }
}
</script>

<style scoped>
#top-menu-subbar a:hover {
  font-weight: bold;
}
#top-menu-subbar li a {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0px;
}
#top-menu-subbar li .active {
  background: #00B8F5;
  font-weight: bold;
}
</style>
